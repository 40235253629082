"use strict";

// Class definition
var WizardBorrower = function () {

  var sourceIncome = function() {
    var sourceIncome = KTUtil.getByID('wizard_borrower_borrower_information_source_income_id');

    if (!sourceIncome) {
      return;
    }

    $(sourceIncome).change(function(){
      var companyName = $('#wizard_borrower_borrower_information_company_name')
      if($(this).val() == 1) {
        companyName.prop('disabled', true);
      } else {
        companyName.prop('disabled', false);
      }
    })
  };

  var removeButton = function() {
    $('form').on('click', '.remove_record', function(event) {
      $(this).prev('input[type=hidden]').val('1');
      $(this).closest('.' + $(this).data('target')).hide();
      return event.preventDefault();
    });
  };

  var addButton = function() {
    $('form').on('click', '.add_fields', function(event) {
      var regexp, time;
      var time = new Date().getTime();
      var target = $(this).data('target')
      var regexp = new RegExp($(this).data('id'), 'g');
      $('#' + target).append($(this).data('fields').replace(regexp, time));
      return event.preventDefault();
    });
  };

  var carMaker = function() {
    $('.car_maker').change(function() {
      var el = $(this)
      var model = el.closest('.vehicle').find('.car_model')
      model.data('dynamic-url', model.data('dynamic-selectable-url').replace(/:maker_id/, el.val()))
      el.closest('.vehicle').find('.car_year').html(`<option></option>`)
      el.closest('.vehicle').find('.car_body').html(`<option></option>`)
      return event.preventDefault()
    });
  };

  var carModel = function() {
    $('.car_model').change(function() {
      var el = $(this)
      var maker = el.closest('.vehicle').find('.car_maker')
      var year = el.closest('.vehicle').find('.car_year')
      year.data('dynamic-url', year.data('dynamic-selectable-url').replace(/:maker_id/, maker.val()).replace(/:model_id/, el.val()))
      el.closest('.vehicle').find('.car_body').html(`<option></option>`)
      return event.preventDefault()
    });
  };

  return {
    // public functions
    init: function() {
      sourceIncome();
      removeButton();
      addButton();
      carMaker();
      carModel();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = WizardBorrower;
}
