// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// import Rails from "@rails/ujs"
Rails.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

//----------------------------------------------------------------------------

window.$ = require('jquery');

import '../nose/bootstrap_custom.js'
import '../nose/application'

import WizardMoneylender from '../nose/wizard_moneylender'
import WizardBorrower from '../nose/wizard_borrower'
import Lender from '../nose/lender'
import Borrow from '../nose/borrow'

window.Lender = Lender;
window.Borrow = Borrow;


require("ion-rangeslider")
require("zingchart")
require('../layout/plugins/bootstrap-datepicker/bootstrap-datepicker')
require('../layout/plugins/bootstrap-datepicker/bootstrap-datepicker.es.min')
require('../plugins/jquery.formatCurrency-1.4.0/jquery.formatCurrency-1.4.0.min')
require('../plugins/jquery.formatCurrency-1.4.0/i18n/jquery.formatCurrency.es-PE')

var KTIONRangeSlider = function () {
  var calculateMoneylender = function () {
    var amount = $('#moneylender-amount').val();
    var rate = $('#moneylender-rate').val();
    var period = $('#moneylender-period').val();
    Rails.ajax({
      url: 'investment_calculators',
      data: 'investment_calculator[amount]='+amount+'&investment_calculator[percentage]='+rate+'&investment_calculator[period]='+period,
      type: 'GET',
      dataType: 'script',
      success: function(response) {
        // console.log(response);
        $('.year-1').html('S/ ' + parseFloat(response[1]).toFixed(2));
        $('.year-2').html('S/ ' + parseFloat(response[2]).toFixed(2));
        $('.year-3').html('S/ ' + parseFloat(response[3]).toFixed(2));
        $('.year-4').html('S/ ' + parseFloat(response[4]).toFixed(2));
      }
    });
  }

  var calculateBorrower = function () {
    var amount = $('#borrower-amount').val();
    var rate = $('#borrower-rate').val();
    var period = $('#borrower-period').val();
    Rails.ajax({
      url: 'loan_calculators',
      data: 'loan_calculator[amount]='+amount+'&loan_calculator[percentage]='+rate+'&loan_calculator[period]='+period,
      type: 'GET',
      dataType: 'script',
      success: function(response) {
        // console.log(response);
        $('.average_monthly_payment').html('S/ ' + parseFloat(response).toFixed(2));
      }
    });
  }

  var demos = function () {
    $(".moneylender-sliders").ionRangeSlider({
      onFinish: function (data) {
      //   console.log(data.input);        // jQuery-link to input
      //   console.log(data.slider);       // jQuery-link to range sliders container
      //   console.log(data.min);          // MIN value
      //   console.log(data.max);          // MAX values
      //   console.log(data.from);         // FROM value
      //   console.log(data.from_percent); // FROM value in percent
      //   console.log(data.from_value);   // FROM index in values array (if used)
      //   console.log(data.to);           // TO value
      //   console.log(data.to_percent);   // TO value in percent
      //   console.log(data.to_value);     // TO index in values array (if used)
      //   console.log(data.min_pretty);   // MIN prettified (if used)
      //   console.log(data.max_pretty);   // MAX prettified (if used)
      //   console.log(data.from_pretty);  // FROM prettified (if used)
      //   console.log(data.to_pretty);    // TO prettified (if used)
        calculateMoneylender();
      }
    });

    $(".borrower-sliders").ionRangeSlider({
      onFinish: function (data) {
        // console.log(data.input);        // jQuery-link to input
        // console.log(data.slider);       // jQuery-link to range sliders container
        // console.log(data.min);          // MIN value
        // console.log(data.max);          // MAX values
        // console.log(data.from);         // FROM value
        // console.log(data.from_percent); // FROM value in percent
        // console.log(data.from_value);   // FROM index in values array (if used)
        // console.log(data.to);           // TO value
        // console.log(data.to_percent);   // TO value in percent
        // console.log(data.to_value);     // TO index in values array (if used)
        // console.log(data.min_pretty);   // MIN prettified (if used)
        // console.log(data.max_pretty);   // MAX prettified (if used)
        // console.log(data.from_pretty);  // FROM prettified (if used)
        // console.log(data.to_pretty);    // TO prettified (if used)
        calculateBorrower();
      }
    });
  }

  return {
  // public functions
    init: function() {
        demos();
        calculateMoneylender();
        calculateBorrower();
    }
  };
}();

// window.KTIONRangeSlider = KTIONRangeSlider


var KTAppOptions = {
  "colors": {
    "state": {
      "brand": "#5d78ff",
      "dark": "#282a3c",
      "light": "#ffffff",
      "primary": "#5867dd",
      "success": "#34bfa3",
      "info": "#36a3f7",
      "warning": "#ffb822",
      "danger": "#fd3995"
    },
    "base": {
      "label": [
        "#c5cbe3",
        "#a1a8c3",
        "#3d4465",
        "#3e4466"
      ],
      "shape": [
        "#f0f3ff",
        "#d9dffa",
        "#afb4d4",
        "#646c9a"
      ]
    }
  }
};

window.KTAppOptions = KTAppOptions;
import PerfectScrollbar from 'perfect-scrollbar';

window.PerfectScrollbar = PerfectScrollbar;
window.Cookies          = require('js-cookie');
window.Sticky           = require('sticky-js');

window.KTApp       = require("../layout/js/global/components/base/app");
window.KTHeader    = require("../layout/js/global/components/base/header");
window.KTMenu      = require("../layout/js/global/components/base/menu");
window.KTOffcanvas = require("../layout/js/global/components/base/offcanvas");
window.KTPortlet   = require("../layout/js/global/components/base/portlet");
window.KTScrolltop = require("../layout/js/global/components/base/scrolltop");
window.KTToggle    = require("../layout/js/global/components/base/toggle");
window.KTUtil      = require("../layout/js/global/components/base/util");
window.KTLayout    = require("../layout/js/global/layout/layout");


// window.KTAppOptions = KTAppOptions;

// import PerfectScrollbar from '../layout/plugins/perfect-scrollbar/dist/perfect-scrollbar';
// import Cookies          from 'js-cookie';
// import Sticky           from 'sticky-js';

// window.PerfectScrollbar = PerfectScrollbar;
// window.Cookies = Cookies;
// window.Sticky = Sticky;

// import KTUtil      from "../layout/js/global/components/base/util";
// import KTApp       from "../layout/js/global/components/base/app";
// import KTHeader    from "../layout/js/global/components/base/header";
// import KTMenu      from "../layout/js/global/components/base/menu";
// import KTOffcanvas from "../layout/js/global/components/base/offcanvas";
// import KTScrolltop from "../layout/js/global/components/base/scrolltop";
// import KTToggle    from "../layout/js/global/components/base/toggle";
// import KTLayout    from "../layout/js/global/layout/layout";


// window.KTUtil = KTUtil;
// window.KTApp = KTApp;
// window.KTHeader = KTHeader;
// window.KTMenu = KTMenu;
// window.KTOffcanvas = KTOffcanvas;
// window.KTScrolltop = KTScrolltop;
// window.KTToggle = KTToggle;
// window.KTLayout = KTLayout;


// document.addEventListener("turbolinks:load", function() {
  // console.log("cargo turbo");
//   console.log($('.ps'));
//   // window.KTApp.init();
//   // window.KTLayout.init();
//   // window.KTLayout.initHeader();
// });

// $(function() {
  // console.log($('.kt-scroll'));
  // $('.kt-scroll').each(function(){
  //   window.KTUtil.scrollUpdate($(this));
  // });

//   window.KTUtil.scrollUpdateAll(document);
  // window.KTLayout.init();
  // window.KTApp.init();
//  window.KTUtil.init();
// window.KTLayout.initHeader();
// });

// $(function() {
//   window.KTLayout.init();
// });


//---------------------------------------------------------------------------



var Selectables = function () {
  var init = function () {
    $('form').on('change', '.selectable', function(event) {
      var el = $(this)
      if ( el.data('dynamic-selectable-url') && el.data('dynamic-selectable-target') ) {
        var urlTemplate = el.data('dynamic-url') || el.data('dynamic-selectable-url')
        var target = el.data('dynamic-selectable-target')
        var targetSelect = el.parent().parent().parent().find(target)
        var url = null;
        targetSelect.html(`<option></option>`)
        if (el.val() && el.val() != '') {
          url = urlTemplate.replace(/:.+_id/, el.val())
        }
        if (url) {
          Rails.ajax({
            url: url,
            type: "GET",
            success: function(data) {
              data.forEach(function(row) {
                var option = `<option value='${row.id}'>${row.name}</option>`
                targetSelect.append(option)
              });
            }
          })
        }
        return event.preventDefault()
      }
    })
  }

  return {
  // public functions
    init: function() {
      init();
    }
  };
}();

// ---------------------------------

var BootstrapDatepicker = function () {

  var arrows = {
      leftArrow: '<i class="la la-angle-left"></i>',
      rightArrow: '<i class="la la-angle-right"></i>'
  }

  // Private functions
  var init = function () {
    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: "bottom left",
      templates: arrows,
      language: 'es'
  });
  }

  return {
    // public functions
    init: function() {
        init();
    }
  };
}();

//------------------------

document.addEventListener("turbolinks:load", function() {
  console.log("ENTROOo")
// $(document).on('turbolinks:load', function() {
  // $('body').append("<div>DESDE EL JS</div>");
  // $('.mb-n1').append("<div>Hola</div>");
  // window.onload = function() {
  console.log("ONLOAD");
    // window.KTUtil.removeClass(window.KTUtil.get('body'), 'kt-page--loading');
  $('body').removeClass('kt-page--loading');
// }
//   window.KTUtil.ready(function() {
//     console.log("listo");
//     window.KTUtil.init();
  var bodyClasses = $('body').attr('class')
  if( bodyClasses != undefined && bodyClasses.split(/\s+/).length > 0) {
    console.log("TURBO CON BODY")
    window.KTApp.init(window.KTAppOptions);
    window.KTLayout.init();
    WizardMoneylender.init();
    WizardBorrower.init();
  } else {
    KTIONRangeSlider.init();
  }
  Selectables.init();
  BootstrapDatepicker.init();
});

$(document).on("turbolinks:before-cache", function () {
  // console.log("before cache");
  // KTUtil.addClass(KTUtil.get('body'), 'kt-page--loading');
});


document.addEventListener('turbolinks:render', function() {
  console.log("turbolinks:render");
});
