"use strict";

// Class definition
var Lender = function () {

  var calculate_lender_bid = function(form) {
    var amount = parseFloat(form.find('#lender_bids_loan_application_amount').val());
    var rate = parseFloat(form.find('#lender_bids_loan_application_interest_rate').val());
    var periods = parseInt(form.find('#lender_bids_loan_application_periods').val());
    if (amount && rate && periods) {
      Rails.ajax({
        url: 'bid_calculations',
        data: 'lender_bid_calculation[amount]='+amount+'&lender_bid_calculation[interest_rate]='+rate+'&lender_bid_calculation[periods]='+periods,
        type: 'POST',
        dataType: 'script',
        success: function(response) {
          form.next('div').find('.amount').html(response.amount);
          form.next('div').find('.platform_cost').html(response.platform_cost);
          form.next('div').find('.total_amount').html(response.total_amount);
          form.next('div').find('.total_interest').html(response.total_interest);
          form.next('div').find('.total_monthly_payment').html(response.total_monthly_payment);
          $('.format_currency').formatCurrency({region: 'es-PE'});
        }
      });
    }
  };

  var loanApplication = function() {
    $('#lender_bids_loan_application_amount, #lender_bids_loan_application_interest_rate, #lender_bids_loan_application_periods').change(function() {
      calculate_lender_bid($(this).closest('form'));
    });
  }

  return {
    // public functions
    init: function() {
      loanApplication();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = Lender;
}
