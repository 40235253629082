"use strict";

// Class definition
var WizardMoneylender = function () {

  var source_income = function() {
    var sourceIncome = KTUtil.getByID('wizard_moneylender_moneylender_information_source_income_id');

    if (!sourceIncome) {
      return;
    }

    $(sourceIncome).change(function(){
      var companyName = $('#wizard_moneylender_moneylender_information_company_name')
      if($(this).val() == 1) {
        companyName.prop('disabled', true);
      } else {
        companyName.prop('disabled', false);
      }
    })
  }

  return {
    // public functions
    init: function() {
        source_income();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = WizardMoneylender;
}
