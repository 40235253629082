"use strict";

// Class definition
var Borrow = function () {

  var simulate = function() {
    var loanApplicationId = $('#bids-loan-applications-list').data('loan-application-id');
    var url = '' + loanApplicationId + '/loan_simulations';
    var allVals = $(".simulate:checked").map(
      function () {return 'bids_loan_application_ids[]=' + this.value.toString();}).get();
    var data = allVals.join('&');
    Rails.ajax({
      url: url,
      type: 'POST',
      data: data,
      dataType: 'script'
    });
  };

  var simulation = function() {
    $('.simulate').click(function() {
      simulate();
    });
  }

  return {
    // public functions
    init: function() {
      simulation();
    }
  };
}();

// webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  module.exports = Borrow;
}
